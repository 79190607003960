/**
 * A small module to make sending Hubspot events a little easier.
 *
 * Each event we want to trigger via JS should go through these steps:
 *  - create the event in Hubspot
 *  - add the event ID to the `events` variable
 *  - create a function in the return object that dispatches the new event
 */
window.ONNIT = window.ONNIT || {};

window.ONNIT.hubspot = function() {
    var _hsq = window._hsq = window._hsq || [];

    /**
     * List of event IDs that are defined in Hubspot.
     * This should stay in sync with atlas-api's HubspotEventsEnum
     *
     * @type Object
     */
    var events = {
        PRODUCT_ADDED_TO_CART: '000005647484',
        PRODUCT_REMOVED_FROM_CART: '000005647485',
        PURCHASE_COMPLETED: '000005669151',
        EMAIL_LIST_SIGNUP: '000005715265',
        NEW_CUSTOMER_CREATED: '000006018461',
        SUBSCRIPTION_CREATED: '000006055443',
        SUBSCRIPTION_PAUSED: '000006050988',
        SUBSCRIPTION_RESUMED: '000006050989',
        SUBSCRIPTION_CANCELED: '000006050990',
        UPSELL_ORDER_PLACED: '000006050991',
        DIGITAL_PRODUCT_DOWNLOADED: '000006109206',
        SHOPPING_CART_VIEWED: '000006173309'
    };

    /**
     * Returns `true` if the current request is from a masquerading user (i.e. if the masquerading cookie is set to '1')
     * and `false` otherwise.
     *
     * @return {boolean}
     */
    function isMasquerading() {
        return window.document.cookie.match(/(^|; )ATLAS_ACCESS_MASQUERADE=1(;|$)/) !== null;
    }

    /**
     * Checks if the tracking code API is enabled.
     *
     * The tracking code API will be disabled if the user is masquerading.
     *
     * @return {boolean}
     */
    function isEnabled() {
        return !isMasquerading();
    }

    /**
     * Pushes a function call onto the _hsq array.
     * https://developers.hubspot.com/docs/methods/tracking_code_api/tracking_code_overview
     *
     * @param {string} name
     * @param {...*} args
     */
    function hsq(name, args) {
        if (!isEnabled()) {
            return;
        }
        _hsq.push(Array.prototype.slice.call(arguments));
    }

    /**
     * Send the Hubspot tracking event. Optionally, send a revenue value.
     * https://developers.hubspot.com/docs/methods/tracking_code_api/javascript_events_api
     *
     * @param String  id
     * @param Integer revenue
     */
    function trackEvent(id, revenue) {
        revenue = revenue || false;
        var event = { id: id };

        if (revenue) {
            event.value = revenue;
        }

        hsq('trackEvent', event);
    }

    /**
     * Send the Hubspot identify event. Payload can contain contact properties to update.
     * https://developers.hubspot.com/docs/methods/tracking_code_api/identify_visitor
     *
     * @param Object payload
     */
    function identify(payload) {
        hsq('identify', payload);
    }

    /**
     * Sign up an email address to one or more lists.
     *
     * @param   String       email
     * @param   String|Array listNames Single list name as string, or multiple names as array.
     * @param   String|Null  leadSource
     * @param   Object|Null  customContactProperties Contact properties that should be set for the contact identified by email.
     * @returns Boolean
     */
    function emailListSignup(email, listNames, leadSource, contactProperties) {
        // Validate required params
        if (typeof email !== 'string' || !email.trim().length) {
            return false;
        }

        listNames = (listNames instanceof Array) ? listNames : [listNames];
        if (!listNames.length) {
            return false;
        }

        // Build payload
        var payload = {email: email};
        if (typeof leadSource === 'string' && leadSource.trim().length) {
            payload.lead_source_new = leadSource;
        }

        for (var i = 0; i < listNames.length; ++i) {
            if (typeof listNames[i] === 'string' && listNames[i].trim().length) {
                payload[listNames[i]] = true;
            }
        }

        if (typeof contactProperties === 'object' && !Array.isArray(contactProperties)) {
            payload = Object.assign(payload, contactProperties);
        }

        // Update contact
        identify(payload);
        trackEvent(events.EMAIL_LIST_SIGNUP);
        console.debug("HubSpot: email list signup successful. Email=%s ListNames=%s LeadSource=%s ContactProperties=%s", email, listNames, leadSource, JSON.stringify(contactProperties));

        // Google Tag Manager
        if (window.ONNIT && window.ONNIT.googleTagManager) {
            window.ONNIT.googleTagManager.newsletterRegistered(leadSource || 'onnit:newsletter');
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            'event': 'fbqTrack',
            'fbqEvent': 'Lead',
            'fbqData': {}
        });

        return true;
    }

    return {
        identify: identify,
        emailListSignup: emailListSignup,
        newCustomerCreated: function(data) {
            identify(data);
            trackEvent(events.NEW_CUSTOMER_CREATED);
        },
        productAddedToCart: function() {
            trackEvent(events.PRODUCT_ADDED_TO_CART);
        },
        productRemovedFromCart: function() {
            trackEvent(events.PRODUCT_REMOVED_FROM_CART);
        },
        purchaseCompleted: function(orderTotal) {
            trackEvent(events.PURCHASE_COMPLETED, orderTotal);
        },
        digitalProductDownloaded: function() {
            trackEvent(events.DIGITAL_PRODUCT_DOWNLOADED);
        },
        shoppingCartViewed: function() {
            trackEvent(events.SHOPPING_CART_VIEWED);
        }
    };
}();

window.ONNIT.personalize = function() {
    var getConfigurations = function() {
        if (!window.onnit_context || !window.onnit_context.personalize) {
            throw new Error('Personalize: configurations are missing.');
        }

        if (!window.onnit_context.personalize.enable) {
            throw new Error('Personalize: Failed to make request, personalize is not enabled.');
        }

        if (!window.onnit_context.personalize.api_url) {
            throw new Error("Personalize: Failed to make request, `api_url` is missing.");
        }

        if (!window.onnit_context.personalize.api_key) {
            throw new Error("Personalize: Failed to make request, `api_key` is missing.");
        }

        if (!window.onnit_context.personalize.session_id) {
            throw new Error("Personalize: Failed to make request, `session_id` is missing.");
        }

        return window.onnit_context.personalize;
    },

    getContext = function(config) {
        var context = {};

        if (!config) {
            config = getConfigurations();
        }

        if (config.country) {
            context.COUNTRY = config.country;
        }

        if (config.device) {
            context.DEVICE = config.device;
        }

        if (window.ONNIT.googleTagManager && ONNIT.googleTagManager.getFromDataLayer) {
            var page_type = ONNIT.googleTagManager.getFromDataLayer('cg1');
            if (page_type) {
                context.PAGE_TYPE = page_type.replace(/\W+/g, '_').toUpperCase();
            }

            var categories = [
                ONNIT.googleTagManager.getFromDataLayer('cg2'),
                ONNIT.googleTagManager.getFromDataLayer('cg3')
            ].filter(function(category) { return category; });

            if (categories.length > 0) {
                context.BLOG_CATEGORIES = categories.map(function(category) {
                    return category.replace(/\W+/g, '_').toUpperCase();
                }).join('|');
            }
        }

        return context;
    },

    xhrSend = function(options) {
        var xhr = new XMLHttpRequest;
        xhr.open(options.method, options.url);
        xhr.timeout = options.timeout || 1500;

        var headers = options.headers || {};
        for (var key in headers) {
            xhr.setRequestHeader(key, headers[key]);
        }

        if (options.success) {
            xhr.onload = function () {
                options.success(JSON.parse(this.responseText));
            };
        }

        if (options.error) {
            xhr.onerror = options.error;
            xhr.onabort = options.error;
            xhr.ontimeout = options.error;
        }

        xhr.send(options.data);
    },

    getXhrSettings = function(path, payload, config) {
        return {
            url: config.api_url + path,
            method: 'POST',
            data: JSON.stringify(payload),
            headers: {
                'X-Api-Key': config.api_key,
                'Content-Type': 'application/json'
            },
            timeout: 1500
        };
    },

    pushEvent = function(type, event) {
        var config = getConfigurations(),
            context = getContext(config),
            payload = {
                Event: event,
                SessionId: config.session_id,
                EventType: type
            },
            eventContextKey;

        if (config.user_id) {
            payload.UserId = config.user_id;
        }

        for (var key in context) {
            eventContextKey = key.toLowerCase().split('_').map(function(word, index){
                return index === 0 ? word : (word.charAt(0).toUpperCase() + word.substring(1));
            }).join('');

            payload.Event[eventContextKey] = context[key];
        }

        xhrSend(getXhrSettings('/events', payload, config));
    },

    getRanking = function(inputList, callbacks) {
        var config = getConfigurations(),
            payload = {
                userId: config.user_id || config.session_id,
                inputList: inputList,
                context: getContext(config)
            },
            settings = getXhrSettings('/ranking', payload, config);

        if (!callbacks || !callbacks.success) {
            throw new Error('Personalize: getRanking requires a success callback');
        }

        settings.success = callbacks.success;
        settings.error = callbacks.error;

        xhrSend(settings);
    };

    return {
        pushEvent: pushEvent,
        getRanking: getRanking
    };
}();
